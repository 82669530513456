
const ourServicesList = [
  {
    title: 'Understand the market',
    shortDescription: 'Get a holistic view of the healthcare and pharma market through our insightful ',
    link: '/consulting/market-assessment-services',
    imageSrc: 'img/home/png/understand-the-market.png',
    altText: 'Get a holistic view of the healthcare and pharma market through our insightful ',
    buttonTitle: 'Know More',
  },
  {
    title: 'Estimate the forecast',
    shortDescription: 'Be the leader in the pharma market with the help of our 10-year market and epidemiology forecast',
    link: '/report-store.php?category=Market Insight',
    imageSrc: 'img/home/png/estimate-the-forecast.png',
    altText: 'Be the leader in the pharma market with the help of our 10-year market and epidemiology forecast',
    buttonTitle: 'Know More',
  },
  {
    title: 'Analyze the pipeline',
    shortDescription: 'Gain rich knowledge of the upcoming therapies for better business-related decisions',
    link: '/report-store.php?category=Pipeline Insight',
    imageSrc: 'img/home/png/analyse-the-pipeline.png',
    altText: 'Gain rich knowledge of the upcoming therapies for better business-related decisions',
    buttonTitle: 'Know More',
  },
  {
    title: 'Monitor the competitors',
    shortDescription: 'Know and inspect your competitors to stand out in the industry',
    link: '/consulting/competitive-intelligence-services',
    imageSrc: 'img/home/png/monitor-the-competitors.png',
    altText: 'Know and inspect your competitors to stand out in the industry',
    buttonTitle: 'Know More',
  },
  {
    title: 'Streamline your journey',
    shortDescription: 'Consulting services that help in meeting desired goals through optimized strategy',
    link: '/consulting/asset-prioritizaton-services',
    imageSrc: 'img/home/png/streamline-your-journey.png',
    altText: 'Consulting services that help in meeting desired goals through optimized strategy',
    buttonTitle: 'Know More',
  },
  {
    title: 'Accelerate your growth',
    shortDescription: 'Exploit innovative ideas through our in-depth and curated R&D services',
    link: '/consulting/business-development-services',
    imageSrc: 'img/home/png/accelerate-your-growth.png',
    altText: 'Exploit innovative ideas through our in-depth and curated R&D services',
    buttonTitle: 'Know More',
  }
];
const accordionList = [
  {
    title: 'Customizable Reports',
    content: 'Customized reports specific to geographies, clinical trials, epidemiological trends, and markets.',
    link: '/report-store.php',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Solutions',
    content: 'Identify your needs, and our analysts will provide granular-level market intelligence to fulfill them.',
    link: '/request-for-proposal',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Competitive Intelligence',
    content: 'Custom-based analysis of the competitors to meet your unique needs.',
    link: '/consulting/competitive-intelligence-services',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Packages',
    content: 'Hassle-free subscription-based report packages the way you desire through our PharmDelve.',
    link: '/pharmdelve',
    buttonTitle: 'Know More',
  },
];
const counterList = [
  {
    title: 'Reports',
    imageSrc: 'img/home/svg/research_reports.svg',
    altText: 'Reports',
    numbers: '7000',
  },
  {
    title: 'Consulting Projects',
    imageSrc: 'img/home/svg/consulting_projects.svg',
    altText: 'Consulting Projects',
    numbers: '500',
  },
  {
    title: 'Satisfied Clients',
    imageSrc: 'img/home/svg/satisfied_clients.svg',
    altText: 'Satisfied Clients',
    numbers: '1000',
  },
  {
    title: 'Customized Reports',
    imageSrc: 'img/home/svg/customized_reports.svg',
    altText: 'Customized Reports',
    numbers: '1200',
  },
];
const blogList = [
  {
    title: "15 Prominent Indications for Oncolytic Virus Therapy",
    shortDescription: "As cancer continues to be a leading cause of mortality worldwide, there is a growing urgency to develop innovative oncolytic virus therapies that can effectively target and eradicate cancer cells. With its unique ability to selectively infect and replicate within tumor cells, oncolytic virus therapy has emerged as a promising avenue in cancer treatment. Key factors driving oncolytic virus treatment market growth include increasing research and development activities, growing investments from pharmaceutical companies, and expanding oncolytic virus clinical trial pipelines.",
    link: "/blog/top-15-indications-for-oncolytic-virus-therapy",
    imageSrc: "img/home/png/15-prominent-indications-for-oncolytic-virus-therapy.png",
    altText: "15 Prominent Indications for Oncolytic Virus Therapy",
  },
  {
    title: "7 Promising Obesity Drugs Set to Launch by 2027",
    shortDescription: "More than 80 obesity drugs are currently in the pipeline with a strong emphasis on GLP-1 receptor agonists. Glucagon-like peptide-1 (GLP-1) receptor agonists are medications designed to treat type 2 diabetes mellitus and obesity by reducing blood glucose levels and regulating metabolism. Ongoing efforts by several obesity companies aim to refine existing treatments and develop advanced obesity therapies. ",
    link: "/blog/7-obesity-drugs-launch",
    imageSrc: "img/home/png/7-promising-obesity-drugs-set-to-launch-by-2027.png",
    altText: "7 Promising Obesity Drugs Set to Launch by 2027",
  },
  {
    title: "TECELRA Approval for Synovial Sarcoma Treatment – A Ray of Hope",
    shortDescription: "Adaptimmune Therapeutics and patients waiting for synovial sarcoma treatment witnessed a historic moment at the start of this month. After over ten years without new synovial sarcoma treatments, a groundbreaking, first-in-class cell therapy, TECELRA (afamitresgene autoleucel) has been approved by the FDA.",
    link: "/blog/tecelra-for-synovial-sarcoma-treatment",
    imageSrc: "img/home/png/tecelra-approval-for-synovial-sarcoma-treatment--a-ray-of-hope.png",
    altText: "TECELRA Approval for Synovial Sarcoma Treatment – A Ray of Hope",
  },
  {
    title: "WEGOVY: A Leader in the Obesity and Weight Loss Treatment",
    shortDescription: "With the growing global health concern regarding obesity, Novo’s WEGOVY (semaglutide) has emerged as a leading solution in the weight loss treatment market. WEGOVY is a GLP-1 receptor agonist used alongside a low-calorie diet and increased physical activity. This obesity medication is administered as a weekly subcutaneous injection, offering convenience and consistency in obesity management.",
    link: "/blog/wegovy-for-obesity-and-weight-loss",
    imageSrc: "img/home/png/wegovy-a-leader-in-the-obesity-and-weight-loss-treatment.png",
    altText: "WEGOVY: A Leader in the Obesity and Weight Loss Treatment",
  },
  {
    title: "Late-Stage Thyroid Eye Disease Treatments: 4 Prominent Therapies to Consider",
    shortDescription: "With an estimated 1 million patients affected in the US, thyroid eye disease is a burden for some disorders which become sight-threatening in upto 5% of the cases. Until today, only one thyroid eye disease treatment drug for adults has been approved by the FDA. ",
    link: "/blog/4-late-stage-thyroid-eye-disease-treatments",
    imageSrc: "img/home/png/late-stage-thyroid-Eye-disease-treatments-4-prominent-therapies-to-consider.png",
    altText: "Late-Stage Thyroid Eye Disease Treatments: 4 Prominent Therapies to Consider",
  },
  {
    title: "Top 8 Breakthrough Gene Therapies for Retinitis Pigmentosa Treatment",
    shortDescription: "Gene therapy is becoming a promising solution for retinal degenerative diseases, as the retina offers an excellent setting for studying and treating eye conditions. Importantly, it was the first tissue to receive approved gene therapy for genetic disorders in the United States. To date, only one retinitis pigmentosa treatment regimen, LUXTURNA (developed by Spark Therapeutics), has been approved in the United States.",
    link: "/blog/top-8-gene-therapies-for-retinitis-pigmentosa-treatment",
    imageSrc: "img/home/png/to- 8-breakthrough-gene-therapies-for-retinitis-pigmentosa-treatment.png",
    altText: "Top 8 Breakthrough Gene Therapies for Retinitis Pigmentosa Treatment",
  },
];
const reportList = {
  content: [
    {
      title: 'Latest Reports',
      description: 'Get comprehensive insights into the healthcare industry and epidemiology-based market forecasts with a 360° panoramic picture of the market alongside the latest trends, developments, and future opportunities.',
      shortTitle: 'Report Store',
      shortDescription: 'With over 7000 research reports in the repository covering 27 therapeutic areas, stay updated about the most recent trends, news, and developments in the healthcare domain.',
      buttonTitle: 'Explore Now',
    },
  ],
  items: [
    {
      link: '/report-store/oncolytic-virus-cancer-therapy-market',
      imageSrc: 'products/report-image/1723606581164-oncolytic-virus-market.png',
      altText: 'Oncolytic Virus Market',
    },
    {
      link: '/report-store/non-muscle-invasive-bladder-cancer-market',
      imageSrc: 'products/report-image/1723606754314-non-muscle-invasive-bladder-cancer.png',
      altText: 'Non-Muscle Invasive Bladder Cancer (NMIBC) Market',
    },
    {
      link: '/report-store/psoriatic-arthritis-market',
      imageSrc: 'products/report-image/1723606797526-psoriatic-arthritis-market.png',
      altText: 'Psoriatic Arthritis Market',
    },
    {
      link: '/report-store/graft-versus-host-disease-gvhd-market',
      imageSrc: 'products/report-image/1723606713138-graft-versus-host-disease.png',
      altText: 'Graft Versus Host Disease Market',
    },
    {
      link: '/report-store/weight-loss-weight-management-obesity-market',
      imageSrc: 'products/report-image/1723606633717-obesity.png',
      altText: 'Obesity Market',
    },
  ],
};
export {
  ourServicesList,
  accordionList,
  counterList,
  blogList,
  reportList,
}