import Link from 'next/link'
import React from 'react'
import BorderBtn from '../Common/BorderBtn'

const StripeSection = () => {
    return (
        <div>
            <section className="bg-black">
                <div className="flex flex-col w-11/12 py-3 mx-auto xl:max-w-screen max-w-screen-2xl md:flex-row md:justify-between md:items-center lg:flex-row">
                    <h3 className="pb-5 text-base font-semibold leading-6 text-center text-white md:text-left md:pb-0">
                    Stay tuned with exclusive ESMO 2024 coverage to get all the latest oncology updates.
                    </h3>
                    <div className="flex items-center justify-end px-4 py-2 mx-auto space-x-2 text-white rounded md:mx-0">
                        <BorderBtn
                            buttonLink="esmo-conference-2024"
                            buttonTitle="Connect Now"
                            customClass="text-white bg-[#1b5df3] border-0 rounded-none p-2"
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}
export default StripeSection
